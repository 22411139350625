//-----------------------------------------------------------------------------------
//
//  config.scss
//
//  1. Base Colors
//  2. Line Height Variables
//  3. Margins
//  4. Font Families
//  5. Breakpoint Setting
//
//-----------------------------------------------------------------------------------

//////////////////////////////////////////////////////////
// 1. Base Colors
//////////////////////////////////////////////////////////

$color-main:     #DD2922;
$color-sub:      #DFD5CB;
$color-white:     #fff;
$color-black:        #000;
$color-gray:        #F7F7F7;


//////////////////////////////////////////////////////////
// 2. Line Height Variables
//////////////////////////////////////////////////////////

$line-height:              1.6;

//////////////////////////////////////////////////////////
// 3. Margins
//////////////////////////////////////////////////////////

$margin:       15px;

//////////////////////////////////////////////////////////
// 4. Font Families
//////////////////////////////////////////////////////////

@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);

$font-body: 'Noto Sans JP',  "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$font-en: 'Roboto', sans-serif;
$font-serif: 'Noto Serif JP', 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", serif;


//////////////////////////////////////////////////////////
// 5. Breakpoint Setting
//////////////////////////////////////////////////////////

$breakpoint-up: (
  'xxs': 'screen and (min-width: 360px)',
  'xs': 'screen and (min-width: 425px)',
  'sm': 'screen and (min-width: 554px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 992px)',
  'xls': 'screen and (min-width: 1151px)',
  'xl': 'screen and (min-width: 1200px)',
  'xxl': 'screen and (min-width: 1900px)',
) !default;

$breakpoint-down: (
  'xxs': 'screen and (max-width: 359px)',
  'xs': 'screen and (max-width: 424px)',
  'sm': 'screen and (max-width: 553px)',
  'md': 'screen and (max-width: 767px)',
  'lg': 'screen and (max-width: 991px)',
  'xl': 'screen and (max-width: 1199px)',
) !default;
