#works {
  .c-title {
    margin-top: 50px;
  }
  .works-list {
    margin-top: 50px;
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -15px;

      @include media-up(md){
        margin-top: -15px;
      }

      li {
        width: calc(50% - 15px);
        margin-left: 15px;

        @include media-up(md){
          width: calc(33.333% - 15px);
          margin-left: 15px;
          margin-top: 15px;
        }

        @include media-up(lg){
          width: calc(25% - 15px);
        }

        @include media-up(xxl){
          width: calc(20% - 15px);
        }

        &:nth-child(n + 1) {
          margin-top: 20px;


          @include media-up(md){
            margin-top: 15px;
          }
        }

        &.is--pickup {
          width: 100%;

          @include media-up(lg){
            width: calc(50% - 15px);
          }
        }

        a {
          display: block;
          text-decoration: none;
          color: $color-black;

          @include media-up(md){
            transform: scale(1.0);
            transition: all .1s ease;

            &:hover {
              transform: scale(0.95);
              opacity: 0.7;
            }
          }
        }

        dl {
          position: relative;
          dt {
            font-size: 10px;
            position: absolute;
            bottom: 0px;
            left:0px;
            padding: 10px;
            width: 100%;
            color: $color-black;
            background-color: rgba(#fff,0.7);

            @include media-up(lg){
              font-size: 14px;
            }
          }

          dd {
            img {
              width: 100%;
              height: auto;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  .works-title {
    margin-top: 50px;

    .cat {
      font-size: 12px;
      color: rgb(155, 155, 155);

      @include media-up(md){
        font-size: 14px;
      }

      ul {
        display: flex;

        li {
          line-height: 1;
          + li {
            border-left: 1px dotted rgb(155, 155, 155);
            padding-left: 10px;
            margin-left: 10px;

            @include media-up(md){
              padding-left: 20px;
              margin-left: 20px;
            }
          }
        }
      }
    }

    .date {
      margin-top: 20px;
      font-size: 12px;
      font-family: $font-en;

      @include media-up(md){
        margin-top: 25px;
        font-size: 14px;
      }
    }
    h1 {
      font-size: 20px;
      font-weight: bold;
      margin-top: 0px;
      letter-spacing: 0.01em;

      @include media-up(md){
        font-size: 26px;
        margin-top: 0px;
        letter-spacing: 0.05em;
      }
    }
  }

  .works-image {
    margin-top: 20px;
    text-align: center;

    @include media-up(md){
      margin-top: 40px;
    }
    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }

    &.youtube {
      width: 100%;
      aspect-ratio: 16 / 9;

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    a {
      display: block;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
        transition: all .1s ease;
      }
    }
  }

  .works-text {
    margin-top: 40px;

    p {
      font-size: 13px;
      letter-spacing: 0.01em;
      line-height: 1.8;

      @include media-up(md){
        font-size: 16px;
      }
      + p {
        margin-top: 10px;
      }
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
      margin-top: 40px;

      @include media-up(md){
        font-size: 22px;
      }
      + p {
        margin-top: 20px;
      }
    }

    a {
      color: $color-black;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .works-info {
    margin-top: 50px;
    border-top: 1px solid $color-gray;
    padding-top: 20px;
    line-height: 2;
    letter-spacing: 0.02em;
    font-size: 12px;
    font-family: $font-en;

    @include media-up(md){
      font-size: 13px;
      margin-top: 80px;
      padding-top: 30px;
    }


    a {
      color: $color-black;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
