#footer {
  border-top: 0px solid $color-gray;
  margin-top: 50px;
  padding: 15px;

  @include media-up(md){
    padding: 50px 50px;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
  }



  .footer-nav {
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: -20px;
      margin-top: -10px;

      li {
        margin-left: 20px;
        margin-top: 10px;

        a {
          color: $color-black;
          text-decoration: none;
          font-family: $font-en;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .footer-cl {
    margin-top: 40px;
    text-align: center;
    font-size: 12px;
    font-family: $font-en;
    font-weight: bold;

    @include media-up(md){
      margin-top: 0;
    }

  }
}
