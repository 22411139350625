//-----------------------------------------------------------------------------------
//
//  common.scss
//
//  1. font style
//  2. layout style
//
//-----------------------------------------------------------------------------------

//////////////////////////////////////////////////////////
// 1. font style
//////////////////////////////////////////////////////////

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: $font-body;
  font-size: 14px;
  line-height: 1.6;
  color: $color-black;
  text-align: left;
}

::selection {
    background: $color-main;
    color: $color-white;
    text-shadow: none;
}



.sp-show {
  display: inherit;
  @include media-up(lg){
    display: none;
  }
}

.pc-show {
  display: none;
  @include media-up(lg){
    display: inherit;
  }
}

.wrapper {
  padding-top: 50px;

  @include media-up(md){
    padding-top: 190px;
  }


}


.contents {
  padding: 20px;

  @include media-up(md){
    padding: 0 50px 50px;
  }
}
