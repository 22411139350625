#about {

  .about-concept {
    h1 {
      margin-top: 50px;
      text-align: center;
      margin-bottom: 50px;

      img {
        width: 100%;
        max-width: 250px;
      }
    }

    hr {
      border: 1px solid $color-gray;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    p {
      line-height: 2;

      @include media-up(lg){
        font-size: 14px;
      }
    }
  }

  .about-info {

    .about-info-list {
      margin-top: 40px;

      @include media-up(md){
        display: flex;
        flex-wrap: wrap;
      }
      li {
        @include media-up(md){
          width: 50%;
          margin-top: 10px;
        }
        + li {
          margin-top: 10px;
        }
      }
    }
  }

  .about-business {
    margin-top: 20px;

    @include media-up(md){
      margin-top: 40px;
    }

    img {
      max-width: 100%;
    }

    dl {
      margin-top: 20px;

      @include media-up(md){
      }
      dt {
        font-weight: bold;
        font-size: 20px;
      }

      dd {
        font-size: 12px;
        margin-top: 10px;

        @include media-up(md){
          font-size: 14px;
        }
      }
    }
  }

}
