#recruit {
  .c-title {
    margin-top: 50px;
  }

  .recruit-copy {
    margin-top: 50px;

    h2 {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.01em;

      @include media-up(md){
        font-size: 24px;
        text-align: center;
      }
    }
    p {
      margin-top: 20px;
      line-height: 2;
      letter-spacing: 0.01em;

      @include media-up(md){
        margin-top: 30px;
        line-height: 2.2;
        font-size: 14px;
      }
    }
  }

  .recruit-type {
    margin-top: 50px;
    border-top: 1px solid $color-gray;
    padding-top: 50px;

    @include media-up(md){
      margin-top: 80px;
      padding-top: 80px;
    }

    h3 {
      text-align: center;
      font-size: 18px;
      font-weight: bold;

      @include media-up(md){
        font-size: 20px;
      }
    }

    .recruit-type-list {
      margin-top: 30px;
      display: flex;
      flex-direction: column;

      @include media-up(md){
        flex-direction: row;
        margin-top: 80px;
        justify-content: space-between;
      }

      dl {
        @include media-up(md){
          width: 48%;
        }

        + dl {
          margin-top: 50px;

          @include media-up(md){
            margin-top: 0;
          }
        }

        dt {
          font-size: 18px;
          font-weight: bold;
          border-left: 4px solid #000;
          padding-left: 10px;
          line-height: 1;

          @include media-up(md){
            font-size: 22px;
            padding-left: 20px;
          }
        }

        dd {
          margin-top: 15px;

          @include media-up(md){
            margin-top: 30px;
          }

          p {
            + p {
              margin-top: 20px;
            }

            @include media-up(md){
              font-size: 14px;
              line-height: 2;
            }
          }
        }
      }
    }
  }

  .recruit-info {
    margin-top: 50px;
    border-top: 1px solid $color-gray;
    padding-top: 50px;

    @include media-up(md){
      margin-top: 80px;
      padding-top: 80px;
    }

    h3 {
      text-align: center;
      font-size: 18px;
      font-weight: bold;

      @include media-up(md){
        font-size: 20px;
      }
    }

    .recruit-info-list {
      margin-top: 30px;

      @include media-up(md){
        margin-top: 80px;
      }

      dl {
        display: flex;

        + dl {
          margin-top: 20px;
          border-top: 1px solid $color-gray;
          padding-top: 20px;

          @include media-up(md){
            margin-top: 30px;
            padding-top: 30px;
          }
        }

        &:last-child {
          padding-bottom: 20px;
          // border-bottom: 1px solid $color-gray;

          @include media-up(md){
            padding-bottom: 30px;
          }
        }
        dt {
          width: 120px;

          @include media-up(md){
            width: 200px;
          }
        }
        dd {
          width: calc(100% - 140px);

          @include media-up(md){
            width: calc(100% - 220px);
          }
        }
      }
    }
  }

  .recruit-entry {
    margin-top: 80px;

    a {
      display: flex;
      max-width: 300px;
      font-family: $font-en;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 20px;
      border: 1px solid $color-black;
      margin: 0 auto;
      padding: 20px;
      text-decoration: none;
      color: $color-black;
      letter-spacing: 0.01em;

      @include media-up(md){
        transform: scale(1.0);
        transition: all .1s ease;

        &:hover {
          transform: scale(0.95);
          opacity: 0.7;
        }
      }
    }
  }
}
