#news {
  .c-title {
    margin-top: 50px;
  }
  .news-list {
    margin-top: 50px;
    ul {
      display: flex;
      flex-wrap: wrap;

      @include media-up(md){
        margin-left: -15px;
        margin-top: -15px;
      }

      li {

        @include media-up(md){
          width: calc(33.333% - 15px);
          margin-left: 15px;
          margin-top: 15px;
        }

        @include media-up(lg){
          width: calc(25% - 15px);
        }

        @include media-up(xxl){
          width: calc(20% - 15px);
        }

        + li {
          margin-top: 20px;

          @include media-up(md){
            margin-top: 15px;
          }
        }
        a {
          display: block;
          text-decoration: none;
          color: $color-black;

          @include media-up(md){
            transform: scale(1.0);
            transition: all .1s ease;

            &:hover {
              transform: scale(0.95);
              opacity: 0.7;
            }
          }
        }

        dl {
          position: relative;
          dt {
            font-size: 14px;
            position: absolute;
            bottom: 15px;
            left:15px;
          }

          dd {
            img {
              width: 100%;
              height: auto;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  .news-title {
    margin-top: 50px;

    .date {
      font-size: 12px;
      font-family: $font-en;

      @include media-up(md){
        font-size: 14px;
      }
    }
    h1 {
      font-size: 20px;
      font-weight: bold;
      margin-top: 10px;
      letter-spacing: 0.01em;

      @include media-up(md){
        font-size: 26px;
        letter-spacing: 0.05em;
      }
    }
  }

  .news-image {
    margin-top: 20px;

    @include media-up(md){
      margin-top: 40px;
    }
    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  .news-text {
    margin-top: 40px;

    p {
      font-size: 13px;
      letter-spacing: 0.01em;
      line-height: 1.8;

      @include media-up(md){
        font-size: 16px;
      }
      + p {
        margin-top: 10px;
      }
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
      margin-top: 40px;

      @include media-up(md){
        font-size: 22px;
      }
      + p {
        margin-top: 20px;
      }
    }

    a {
      color: $color-black;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .news-info {
    margin-top: 50px;
    border-top: 1px solid $color-gray;
    padding-top: 20px;
    line-height: 2;
    letter-spacing: 0.02em;
    font-size: 12px;
    font-family: $font-en;

    @include media-up(md){
      font-size: 13px;
      margin-top: 80px;
      padding-top: 30px;
    }


    a {
      color: $color-black;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
