#header {
  background-color: rgba($color-white,0.8);
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  z-index: 9999;

  @include media-up(md){
    // border-bottom: 1px solid $color-gray;
  }

  .header-inner {
    padding: 15px;
    position: relative;

    @include media-up(md){
      padding: 50px 50px;
      display: flex;
      flex-direction: column;
    }

    @include media-up(lg){
    }
  }

  .header-logo {
    width: 100%;
    text-align: center;

    @include media-up(md){
      width: auto;
      text-align: left;
      display: flex;
      align-items: center;
    }
    > * {
      a {
        img {
          height: 20px;
          vertical-align: middle;

          @include media-up(md){
            height: 35px;
          }
        }
      }
    }

    span {
      display: none;

      @include media-up(md){
        display: block;
        font-size: 10px;
        padding-left: 20px;
      }

      @include media-up(lg){
        font-size: 12px;
        padding-left: 40px;
        br {
          display: none;
        }
      }
    }
  }

  .header-nav {
    display: none;
    position: absolute;
    background-color: $color-white;
    right:0;
    top:51px;
    width: 100%;

    @include media-up(md){
      display: flex;
      position: relative;
      background-color: rgba($color-white,0);
      border-top: 0px solid #000;
      right: auto;
      top:auto;
      width: auto;
      margin-top: 30px;
    }

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-family: $font-en;

      li {
        width: 100%;

        @include media-up(md){
          width: auto;
          padding: 0 15px;
        }

        @include media-up(lg){
          padding: 0px;
          margin-right: 20px;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 15px;
          text-decoration: none;
          color: $color-black;
          font-weight: bold;
          letter-spacing: 0.05em;

          @include media-up(md){
            padding: 0;
            transform: scale(1.0);
            transition: all .1s ease;

            &:hover {
              transform: scale(0.95);
              opacity: 0.7;
            }

          }

          @include media-up(lg){
            font-size: 14px;
          }
        }
      }
    }
  }


  .menu {
    position: absolute;
    right: 15px;
    top: 10px;
    height: 53px;
    width: 30px;
    z-index: 9999;
    span {
      position: absolute;
      bottom: 7px;
      margin: 0;
      padding: 0;
      img {
        width: 100%;
        height: auto;
      }
    }
    @include media-up(md){
      display: none;
    }
  }
  .menu .menu-line {
  position: absolute;
  width: 30px;
  left: 0px;
  height: 3px;
  border-radius: 2px;
  background: $color-black;
  }
   .menu .menu-line:nth-of-type(1) {
  top: 8px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  }
   .menu .menu-line:nth-of-type(2) {
  top: 16px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  }
   .menu .menu-line:nth-of-type(3) {
  top: 24px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  }
   .menu.open .menu-line {
  background: $color-black;
  }
   .menu.open .menu-line:nth-of-type(1) {
  top: 16px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  }
   .menu.open .menu-line:nth-of-type(2) {
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  }
   .menu.open .menu-line:nth-of-type(3) {
  top: 16px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  }
}


