// コンテナ
.c-container {
  width: 100%;
  max-width: 1100px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @include media-up(md) {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.c-container-s {
  width: 100%;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
}

.c-title {
  text-align: center;
  font-weight: bold;
  font-family: $font-en;
  font-size: 20px;
  letter-spacing: 0.2em;
  margin-top: 60px;

  @include media-up(md){
    font-size: 24px;
    margin-top: 80px;
  }

  @include media-up(lg){
    margin-top: 150px;
  }
}

.c-workslist {
  width: 100%;
  margin-top: 100px;
  ul {
    display: flex;
    flex-wrap: column;
    flex-wrap: wrap;

    @include media-up(md){
      flex-wrap: row;
    }
  }

  li {
    width: 100%;

    @include media-up(md){
      width: 25%;
    }
    a {
      display: block;
      color: $color-white;

      &:hover {
        dl dt {
          background-color: rgba(#000, 0.5);
        }

        dl dd img {
          transform: scale(1.1);
        }
      }
    }
  }

  dl {
    position: relative;

    dt {
      position: absolute;
      width: 100%;
      height: 300px;
      background-color: rgba(#000, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      text-shadow: 0 3px 5px rgba(#000,0.8);
      font-size: 20px;
      text-align: center;
      z-index: 1;
      transition: all .2s ease;

      @include media-up(md){
        height: 200px;
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
      }

      @include media-up(lg){
        font-size: 20px;
      }

      @include media-up(lg){
        height: 250px;
      }

      span {
        font-size: 14px;

        @include media-up(md){
          font-size: 10px;
          font-weight: normal;
        }

        @include media-up(lg){
          font-size: 14px;
        }
      }
    }

    dd {
      width: 100%;
      overflow: hidden;

      img {
        transition: all .5s ease;
        height: 300px;
        width: 100%;
        object-fit: cover;
        object-position: center center;
        vertical-align: middle;
        transform: scale(1.0);

        @include media-up(md){
          height: 200px;
        }

        @include media-up(lg){
          height: 250px;
        }
      }
    }
  }

}

.is--primary {
  background-color:#EA5840;
}

.is--secondary {
  background-color:#FFB100;
}

.is--en {
  font-family: $font-en;
}

