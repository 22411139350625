//////////////////////////////////////////////////////////
// 1. Media Query
//////////////////////////////////////////////////////////

@mixin media-up($breakpoint: md) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin media-down($breakpoint: md) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

