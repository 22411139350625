@charset "UTF-8";
/*! sanitize.css v7.0.3 | CC0 License | github.com/csstools/sanitize.css */
/* Document
 * ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add border box sizing in all browsers (opinionated).
 */
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: border-box;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Use the default user interface font in all browsers (opinionated).
 * 3. Correct the line height in all browsers.
 * 4. Use a 4-space tab width in all browsers (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */
html {
  cursor: default;
  /* 1 */
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* 2 */
  line-height: 1.15;
  /* 3 */
  -moz-tab-size: 4;
  /* 4 */
  tab-size: 4;
  /* 4 */
  -ms-text-size-adjust: 100%;
  /* 5 */
  -webkit-text-size-adjust: 100%;
  /* 5 */
  word-break: break-word;
  /* 6 */ }

/* Sections
 * ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
 * ========================================================================== */
/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Add the correct display in IE.
 */
main {
  display: block; }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics
 * ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Add the correct text decoration in Edge, IE, Opera, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/*
 * Remove the text shadow on text selections in Firefox 61- (opinionated).
 * 1. Restore the coloring undone by defining the text shadow
 *    in all browsers (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000;
  /* 1 */
  text-shadow: none; }

/* Embedded content
 * ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg:not([fill]) {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data
 * ========================================================================== */
/**
 * Collapse border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse; }

/* Forms
 * ========================================================================== */
/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * Remove the margin in Safari.
 */
button,
input,
select {
  margin: 0; }

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge, Firefox, and IE.
 */
button {
  overflow: visible;
  /* 1 */
  text-transform: none;
  /* 2 */ }

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
 * Show the overflow in Edge and IE.
 */
input {
  overflow: visible; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in Edge and IE.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none; }

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  margin: 0;
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
  /* 3 */ }

/**
 * Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  padding: 0; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText; }

/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge and IE.
 */
details {
  display: block; }

/*
 * Add the correct styles in Edge, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content; }

dialog:not([open]) {
  display: none; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* User interaction
 * ========================================================================== */
/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
  /* 2 */ }

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* Accessibility
 * ========================================================================== */
/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
[aria-disabled="true"],
[disabled] {
  cursor: not-allowed; }

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

iframe {
  border: none !important; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #000;
  text-align: left; }

::selection {
  background: #DD2922;
  color: #fff;
  text-shadow: none; }

.sp-show {
  display: inherit; }
  @media screen and (min-width: 992px) {
    .sp-show {
      display: none; } }

.pc-show {
  display: none; }
  @media screen and (min-width: 992px) {
    .pc-show {
      display: inherit; } }

.wrapper {
  padding-top: 50px; }
  @media screen and (min-width: 768px) {
    .wrapper {
      padding-top: 190px; } }

.contents {
  padding: 20px; }
  @media screen and (min-width: 768px) {
    .contents {
      padding: 0 50px 50px; } }

.c-container {
  width: 100%;
  max-width: 1100px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (min-width: 768px) {
    .c-container {
      padding-right: 25px;
      padding-left: 25px; } }

.c-container-s {
  width: 100%;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto; }

.c-title {
  text-align: center;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  letter-spacing: 0.2em;
  margin-top: 60px; }
  @media screen and (min-width: 768px) {
    .c-title {
      font-size: 24px;
      margin-top: 80px; } }
  @media screen and (min-width: 992px) {
    .c-title {
      margin-top: 150px; } }

.c-workslist {
  width: 100%;
  margin-top: 100px; }
  .c-workslist ul {
    display: flex;
    flex-wrap: column;
    flex-wrap: wrap; }
    @media screen and (min-width: 768px) {
      .c-workslist ul {
        flex-wrap: row; } }
  .c-workslist li {
    width: 100%; }
    @media screen and (min-width: 768px) {
      .c-workslist li {
        width: 25%; } }
    .c-workslist li a {
      display: block;
      color: #fff; }
      .c-workslist li a:hover dl dt {
        background-color: rgba(0, 0, 0, 0.5); }
      .c-workslist li a:hover dl dd img {
        transform: scale(1.1); }
  .c-workslist dl {
    position: relative; }
    .c-workslist dl dt {
      position: absolute;
      width: 100%;
      height: 300px;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      text-shadow: 0 3px 5px rgba(0, 0, 0, 0.8);
      font-size: 20px;
      text-align: center;
      z-index: 1;
      transition: all .2s ease; }
      @media screen and (min-width: 768px) {
        .c-workslist dl dt {
          height: 200px;
          padding: 20px;
          font-size: 16px;
          font-weight: bold; } }
      @media screen and (min-width: 992px) {
        .c-workslist dl dt {
          font-size: 20px; } }
      @media screen and (min-width: 992px) {
        .c-workslist dl dt {
          height: 250px; } }
      .c-workslist dl dt span {
        font-size: 14px; }
        @media screen and (min-width: 768px) {
          .c-workslist dl dt span {
            font-size: 10px;
            font-weight: normal; } }
        @media screen and (min-width: 992px) {
          .c-workslist dl dt span {
            font-size: 14px; } }
    .c-workslist dl dd {
      width: 100%;
      overflow: hidden; }
      .c-workslist dl dd img {
        transition: all .5s ease;
        height: 300px;
        width: 100%;
        object-fit: cover;
        object-position: center center;
        vertical-align: middle;
        transform: scale(1); }
        @media screen and (min-width: 768px) {
          .c-workslist dl dd img {
            height: 200px; } }
        @media screen and (min-width: 992px) {
          .c-workslist dl dd img {
            height: 250px; } }

.is--primary {
  background-color: #EA5840; }

.is--secondary {
  background-color: #FFB100; }

.is--en {
  font-family: "Roboto", sans-serif; }

#header {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999; }
  #header .header-inner {
    padding: 15px;
    position: relative; }
    @media screen and (min-width: 768px) {
      #header .header-inner {
        padding: 50px 50px;
        display: flex;
        flex-direction: column; } }
  #header .header-logo {
    width: 100%;
    text-align: center; }
    @media screen and (min-width: 768px) {
      #header .header-logo {
        width: auto;
        text-align: left;
        display: flex;
        align-items: center; } }
    #header .header-logo > * a img {
      height: 20px;
      vertical-align: middle; }
      @media screen and (min-width: 768px) {
        #header .header-logo > * a img {
          height: 35px; } }
    #header .header-logo span {
      display: none; }
      @media screen and (min-width: 768px) {
        #header .header-logo span {
          display: block;
          font-size: 10px;
          padding-left: 20px; } }
      @media screen and (min-width: 992px) {
        #header .header-logo span {
          font-size: 12px;
          padding-left: 40px; }
          #header .header-logo span br {
            display: none; } }
  #header .header-nav {
    display: none;
    position: absolute;
    background-color: #fff;
    right: 0;
    top: 51px;
    width: 100%; }
    @media screen and (min-width: 768px) {
      #header .header-nav {
        display: flex;
        position: relative;
        background-color: rgba(255, 255, 255, 0);
        border-top: 0px solid #000;
        right: auto;
        top: auto;
        width: auto;
        margin-top: 30px; } }
    #header .header-nav ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-family: "Roboto", sans-serif; }
      #header .header-nav ul li {
        width: 100%; }
        @media screen and (min-width: 768px) {
          #header .header-nav ul li {
            width: auto;
            padding: 0 15px; } }
        @media screen and (min-width: 992px) {
          #header .header-nav ul li {
            padding: 0px;
            margin-right: 20px; } }
        #header .header-nav ul li a {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 15px;
          text-decoration: none;
          color: #000;
          font-weight: bold;
          letter-spacing: 0.05em; }
          @media screen and (min-width: 768px) {
            #header .header-nav ul li a {
              padding: 0;
              transform: scale(1);
              transition: all .1s ease; }
              #header .header-nav ul li a:hover {
                transform: scale(0.95);
                opacity: 0.7; } }
          @media screen and (min-width: 992px) {
            #header .header-nav ul li a {
              font-size: 14px; } }
  #header .menu {
    position: absolute;
    right: 15px;
    top: 10px;
    height: 53px;
    width: 30px;
    z-index: 9999; }
    #header .menu span {
      position: absolute;
      bottom: 7px;
      margin: 0;
      padding: 0; }
      #header .menu span img {
        width: 100%;
        height: auto; }
    @media screen and (min-width: 768px) {
      #header .menu {
        display: none; } }
  #header .menu .menu-line {
    position: absolute;
    width: 30px;
    left: 0px;
    height: 3px;
    border-radius: 2px;
    background: #000; }
  #header .menu .menu-line:nth-of-type(1) {
    top: 8px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  #header .menu .menu-line:nth-of-type(2) {
    top: 16px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  #header .menu .menu-line:nth-of-type(3) {
    top: 24px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  #header .menu.open .menu-line {
    background: #000; }
  #header .menu.open .menu-line:nth-of-type(1) {
    top: 16px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  #header .menu.open .menu-line:nth-of-type(2) {
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  #header .menu.open .menu-line:nth-of-type(3) {
    top: 16px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }

#footer {
  border-top: 0px solid #F7F7F7;
  margin-top: 50px;
  padding: 15px; }
  @media screen and (min-width: 768px) {
    #footer {
      padding: 50px 50px;
      margin-top: 0;
      display: flex;
      justify-content: space-between; } }
  #footer .footer-nav ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-top: -10px; }
    #footer .footer-nav ul li {
      margin-left: 20px;
      margin-top: 10px; }
      #footer .footer-nav ul li a {
        color: #000;
        text-decoration: none;
        font-family: "Roboto", sans-serif; }
        #footer .footer-nav ul li a:hover {
          text-decoration: underline; }
  #footer .footer-cl {
    margin-top: 40px;
    text-align: center;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    font-weight: bold; }
    @media screen and (min-width: 768px) {
      #footer .footer-cl {
        margin-top: 0; } }

#index .index-works ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -15px; }
  @media screen and (min-width: 768px) {
    #index .index-works ul {
      margin-top: -15px; } }
  #index .index-works ul li {
    width: calc(50% - 15px);
    margin-left: 15px; }
    @media screen and (min-width: 768px) {
      #index .index-works ul li {
        width: calc(33.333% - 15px);
        margin-left: 15px;
        margin-top: 15px; } }
    @media screen and (min-width: 992px) {
      #index .index-works ul li {
        width: calc(25% - 15px); } }
    @media screen and (min-width: 1900px) {
      #index .index-works ul li {
        width: calc(20% - 15px); } }
    #index .index-works ul li:nth-child(n + 1) {
      margin-top: 20px; }
      @media screen and (min-width: 768px) {
        #index .index-works ul li:nth-child(n + 1) {
          margin-top: 15px; } }
    #index .index-works ul li.is--pickup {
      width: 100%; }
      @media screen and (min-width: 992px) {
        #index .index-works ul li.is--pickup {
          width: calc(50% - 15px); } }
    #index .index-works ul li a {
      display: block;
      text-decoration: none;
      color: #000; }
      @media screen and (min-width: 768px) {
        #index .index-works ul li a {
          transform: scale(1);
          transition: all .1s ease; }
          #index .index-works ul li a:hover {
            transform: scale(0.95);
            opacity: 0.7; } }
    #index .index-works ul li dl {
      position: relative; }
      #index .index-works ul li dl dt {
        font-size: 10px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        padding: 10px;
        width: 100%;
        color: #000;
        background-color: rgba(255, 255, 255, 0.7); }
        @media screen and (min-width: 992px) {
          #index .index-works ul li dl dt {
            font-size: 14px; } }
      #index .index-works ul li dl dd img {
        width: 100%;
        height: auto;
        vertical-align: middle; }

@media screen and (min-width: 768px) {
  #index .index-about {
    padding-top: 50px;
    margin-top: -50px; } }

@media screen and (min-width: 768px) {
  #index .index-contact {
    padding-top: 50px;
    margin-top: -50px; } }

#works .c-title {
  margin-top: 50px; }

#works .works-list {
  margin-top: 50px; }
  #works .works-list ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -15px; }
    @media screen and (min-width: 768px) {
      #works .works-list ul {
        margin-top: -15px; } }
    #works .works-list ul li {
      width: calc(50% - 15px);
      margin-left: 15px; }
      @media screen and (min-width: 768px) {
        #works .works-list ul li {
          width: calc(33.333% - 15px);
          margin-left: 15px;
          margin-top: 15px; } }
      @media screen and (min-width: 992px) {
        #works .works-list ul li {
          width: calc(25% - 15px); } }
      @media screen and (min-width: 1900px) {
        #works .works-list ul li {
          width: calc(20% - 15px); } }
      #works .works-list ul li:nth-child(n + 1) {
        margin-top: 20px; }
        @media screen and (min-width: 768px) {
          #works .works-list ul li:nth-child(n + 1) {
            margin-top: 15px; } }
      #works .works-list ul li.is--pickup {
        width: 100%; }
        @media screen and (min-width: 992px) {
          #works .works-list ul li.is--pickup {
            width: calc(50% - 15px); } }
      #works .works-list ul li a {
        display: block;
        text-decoration: none;
        color: #000; }
        @media screen and (min-width: 768px) {
          #works .works-list ul li a {
            transform: scale(1);
            transition: all .1s ease; }
            #works .works-list ul li a:hover {
              transform: scale(0.95);
              opacity: 0.7; } }
      #works .works-list ul li dl {
        position: relative; }
        #works .works-list ul li dl dt {
          font-size: 10px;
          position: absolute;
          bottom: 0px;
          left: 0px;
          padding: 10px;
          width: 100%;
          color: #000;
          background-color: rgba(255, 255, 255, 0.7); }
          @media screen and (min-width: 992px) {
            #works .works-list ul li dl dt {
              font-size: 14px; } }
        #works .works-list ul li dl dd img {
          width: 100%;
          height: auto;
          vertical-align: middle; }

#works .works-title {
  margin-top: 50px; }
  #works .works-title .cat {
    font-size: 12px;
    color: #9b9b9b; }
    @media screen and (min-width: 768px) {
      #works .works-title .cat {
        font-size: 14px; } }
    #works .works-title .cat ul {
      display: flex; }
      #works .works-title .cat ul li {
        line-height: 1; }
        #works .works-title .cat ul li + li {
          border-left: 1px dotted #9b9b9b;
          padding-left: 10px;
          margin-left: 10px; }
          @media screen and (min-width: 768px) {
            #works .works-title .cat ul li + li {
              padding-left: 20px;
              margin-left: 20px; } }
  #works .works-title .date {
    margin-top: 20px;
    font-size: 12px;
    font-family: "Roboto", sans-serif; }
    @media screen and (min-width: 768px) {
      #works .works-title .date {
        margin-top: 25px;
        font-size: 14px; } }
  #works .works-title h1 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 0px;
    letter-spacing: 0.01em; }
    @media screen and (min-width: 768px) {
      #works .works-title h1 {
        font-size: 26px;
        margin-top: 0px;
        letter-spacing: 0.05em; } }

#works .works-image {
  margin-top: 20px;
  text-align: center; }
  @media screen and (min-width: 768px) {
    #works .works-image {
      margin-top: 40px; } }
  #works .works-image img {
    width: 100%;
    height: auto;
    vertical-align: middle; }
  #works .works-image.youtube {
    width: 100%;
    aspect-ratio: 16 / 9; }
    #works .works-image.youtube iframe {
      width: 100%;
      height: 100%; }
  #works .works-image a {
    display: block;
    cursor: pointer; }
    #works .works-image a:hover {
      opacity: 0.7;
      transition: all .1s ease; }

#works .works-text {
  margin-top: 40px; }
  #works .works-text p {
    font-size: 13px;
    letter-spacing: 0.01em;
    line-height: 1.8; }
    @media screen and (min-width: 768px) {
      #works .works-text p {
        font-size: 16px; } }
    #works .works-text p + p {
      margin-top: 10px; }
  #works .works-text h2 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 40px; }
    @media screen and (min-width: 768px) {
      #works .works-text h2 {
        font-size: 22px; } }
    #works .works-text h2 + p {
      margin-top: 20px; }
  #works .works-text a {
    color: #000; }
    #works .works-text a:hover {
      text-decoration: none; }

#works .works-info {
  margin-top: 50px;
  border-top: 1px solid #F7F7F7;
  padding-top: 20px;
  line-height: 2;
  letter-spacing: 0.02em;
  font-size: 12px;
  font-family: "Roboto", sans-serif; }
  @media screen and (min-width: 768px) {
    #works .works-info {
      font-size: 13px;
      margin-top: 80px;
      padding-top: 30px; } }
  #works .works-info a {
    color: #000; }
    #works .works-info a:hover {
      text-decoration: none; }

#news .c-title {
  margin-top: 50px; }

#news .news-list {
  margin-top: 50px; }
  #news .news-list ul {
    display: flex;
    flex-wrap: wrap; }
    @media screen and (min-width: 768px) {
      #news .news-list ul {
        margin-left: -15px;
        margin-top: -15px; } }
    @media screen and (min-width: 768px) {
      #news .news-list ul li {
        width: calc(33.333% - 15px);
        margin-left: 15px;
        margin-top: 15px; } }
    @media screen and (min-width: 992px) {
      #news .news-list ul li {
        width: calc(25% - 15px); } }
    @media screen and (min-width: 1900px) {
      #news .news-list ul li {
        width: calc(20% - 15px); } }
    #news .news-list ul li + li {
      margin-top: 20px; }
      @media screen and (min-width: 768px) {
        #news .news-list ul li + li {
          margin-top: 15px; } }
    #news .news-list ul li a {
      display: block;
      text-decoration: none;
      color: #000; }
      @media screen and (min-width: 768px) {
        #news .news-list ul li a {
          transform: scale(1);
          transition: all .1s ease; }
          #news .news-list ul li a:hover {
            transform: scale(0.95);
            opacity: 0.7; } }
    #news .news-list ul li dl {
      position: relative; }
      #news .news-list ul li dl dt {
        font-size: 14px;
        position: absolute;
        bottom: 15px;
        left: 15px; }
      #news .news-list ul li dl dd img {
        width: 100%;
        height: auto;
        vertical-align: middle; }

#news .news-title {
  margin-top: 50px; }
  #news .news-title .date {
    font-size: 12px;
    font-family: "Roboto", sans-serif; }
    @media screen and (min-width: 768px) {
      #news .news-title .date {
        font-size: 14px; } }
  #news .news-title h1 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    letter-spacing: 0.01em; }
    @media screen and (min-width: 768px) {
      #news .news-title h1 {
        font-size: 26px;
        letter-spacing: 0.05em; } }

#news .news-image {
  margin-top: 20px; }
  @media screen and (min-width: 768px) {
    #news .news-image {
      margin-top: 40px; } }
  #news .news-image img {
    width: 100%;
    height: auto;
    vertical-align: middle; }

#news .news-text {
  margin-top: 40px; }
  #news .news-text p {
    font-size: 13px;
    letter-spacing: 0.01em;
    line-height: 1.8; }
    @media screen and (min-width: 768px) {
      #news .news-text p {
        font-size: 16px; } }
    #news .news-text p + p {
      margin-top: 10px; }
  #news .news-text h2 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 40px; }
    @media screen and (min-width: 768px) {
      #news .news-text h2 {
        font-size: 22px; } }
    #news .news-text h2 + p {
      margin-top: 20px; }
  #news .news-text a {
    color: #000; }
    #news .news-text a:hover {
      text-decoration: none; }

#news .news-info {
  margin-top: 50px;
  border-top: 1px solid #F7F7F7;
  padding-top: 20px;
  line-height: 2;
  letter-spacing: 0.02em;
  font-size: 12px;
  font-family: "Roboto", sans-serif; }
  @media screen and (min-width: 768px) {
    #news .news-info {
      font-size: 13px;
      margin-top: 80px;
      padding-top: 30px; } }
  #news .news-info a {
    color: #000; }
    #news .news-info a:hover {
      text-decoration: none; }

#about .about-concept h1 {
  margin-top: 50px;
  text-align: center;
  margin-bottom: 50px; }
  #about .about-concept h1 img {
    width: 100%;
    max-width: 250px; }

#about .about-concept hr {
  border: 1px solid #F7F7F7;
  margin-top: 30px;
  margin-bottom: 30px; }

#about .about-concept p {
  line-height: 2; }
  @media screen and (min-width: 992px) {
    #about .about-concept p {
      font-size: 14px; } }

#about .about-info .about-info-list {
  margin-top: 40px; }
  @media screen and (min-width: 768px) {
    #about .about-info .about-info-list {
      display: flex;
      flex-wrap: wrap; } }
  @media screen and (min-width: 768px) {
    #about .about-info .about-info-list li {
      width: 50%;
      margin-top: 10px; } }
  #about .about-info .about-info-list li + li {
    margin-top: 10px; }

#about .about-business {
  margin-top: 20px; }
  @media screen and (min-width: 768px) {
    #about .about-business {
      margin-top: 40px; } }
  #about .about-business img {
    max-width: 100%; }
  #about .about-business dl {
    margin-top: 20px; }
    #about .about-business dl dt {
      font-weight: bold;
      font-size: 20px; }
    #about .about-business dl dd {
      font-size: 12px;
      margin-top: 10px; }
      @media screen and (min-width: 768px) {
        #about .about-business dl dd {
          font-size: 14px; } }

#solution .c-title {
  margin-top: 50px; }

#solution img {
  max-width: 100%; }

#solution .solution-cover {
  margin: 0px 0px 80px; }
  #solution .solution-cover img {
    max-width: 100%; }

#solution .solution-list {
  margin-top: 50px; }
  #solution .solution-list dl + dl {
    margin-top: 80px; }
  #solution .solution-list dl dt {
    font-size: 22px;
    font-weight: bold; }
    @media screen and (min-width: 768px) {
      #solution .solution-list dl dt {
        font-size: 24px; } }
    @media screen and (min-width: 992px) {
      #solution .solution-list dl dt {
        font-size: 30px; } }
  #solution .solution-list dl dd {
    margin-top: 10px; }
    @media screen and (min-width: 992px) {
      #solution .solution-list dl dd {
        display: flex; } }
    #solution .solution-list dl dd hr {
      border: 1px solid #F7F7F7;
      margin-top: 20px;
      margin-bottom: 20px; }
      @media screen and (min-width: 992px) {
        #solution .solution-list dl dd hr {
          display: none; } }
    #solution .solution-list dl dd .solution-info {
      font-size: 14px; }
      @media screen and (min-width: 992px) {
        #solution .solution-list dl dd .solution-info {
          font-size: 15px;
          line-height: 2;
          width: 50%;
          margin-right: 5%; } }
    #solution .solution-list dl dd .solution-machine {
      font-size: 12px; }
      @media screen and (min-width: 992px) {
        #solution .solution-list dl dd .solution-machine {
          width: 45%;
          font-size: 13px; } }

#solution .solution-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px; }
  #solution .solution-item + .solution-item {
    margin-top: 80px; }
  #solution .solution-item .solution-item__title {
    font-size: 22px;
    font-weight: bold; }
    @media screen and (min-width: 768px) {
      #solution .solution-item .solution-item__title {
        font-size: 24px; } }
    @media screen and (min-width: 992px) {
      #solution .solution-item .solution-item__title {
        font-size: 30px; } }
  #solution .solution-item .solution-item__contents {
    margin-top: 10px; }
    @media screen and (min-width: 992px) {
      #solution .solution-item .solution-item__contents {
        display: flex;
        margin-top: 20px; } }
    @media screen and (min-width: 992px) {
      #solution .solution-item .solution-item__contents .solution-item__photo {
        width: 40%; } }
    #solution .solution-item .solution-item__contents .solution-item__text {
      margin-top: 20px; }
      @media screen and (min-width: 768px) {
        #solution .solution-item .solution-item__contents .solution-item__text {
          display: flex;
          margin-top: 0; } }
      @media screen and (min-width: 992px) {
        #solution .solution-item .solution-item__contents .solution-item__text {
          width: 58%;
          margin-left: 2%; } }
      #solution .solution-item .solution-item__contents .solution-item__text hr {
        border: 1px solid #F7F7F7;
        margin-top: 20px;
        margin-bottom: 20px; }
        @media screen and (min-width: 992px) {
          #solution .solution-item .solution-item__contents .solution-item__text hr {
            display: none; } }
      #solution .solution-item .solution-item__contents .solution-item__text .solution-info {
        font-size: 14px; }
        @media screen and (min-width: 768px) {
          #solution .solution-item .solution-item__contents .solution-item__text .solution-info {
            width: 50%;
            margin-right: 5%; } }
        @media screen and (min-width: 992px) {
          #solution .solution-item .solution-item__contents .solution-item__text .solution-info {
            font-size: 15px;
            line-height: 2;
            width: 63%;
            margin-right: 2%; } }
        #solution .solution-item .solution-item__contents .solution-item__text .solution-info span {
          font-size: 12px; }
      #solution .solution-item .solution-item__contents .solution-item__text .solution-machine {
        font-size: 12px; }
        @media screen and (min-width: 768px) {
          #solution .solution-item .solution-item__contents .solution-item__text .solution-machine {
            width: 45%; } }
        @media screen and (min-width: 992px) {
          #solution .solution-item .solution-item__contents .solution-item__text .solution-machine {
            font-size: 13px;
            width: 35%; } }

#qa .c-title {
  margin-top: 50px; }

#qa .qa-list {
  margin-top: 50px; }
  #qa .qa-list dl {
    display: flex;
    flex-direction: column; }
    #qa .qa-list dl + dl {
      margin-top: 60px; }
    #qa .qa-list dl dt {
      font-size: 14px;
      font-weight: bold; }
      @media screen and (min-width: 768px) {
        #qa .qa-list dl dt {
          font-size: 16px; } }
      @media screen and (min-width: 992px) {
        #qa .qa-list dl dt {
          font-size: 18px; } }
    #qa .qa-list dl dd {
      margin-top: 20px;
      padding-left: 20px;
      border-left: 2px solid #000; }
      #qa .qa-list dl dd strong {
        font-weight: bold; }
        @media screen and (min-width: 768px) {
          #qa .qa-list dl dd strong {
            font-size: 16px; } }
        @media screen and (min-width: 992px) {
          #qa .qa-list dl dd strong {
            font-size: 18px; } }
      #qa .qa-list dl dd p {
        margin-top: 10px; }

#recruit .c-title {
  margin-top: 50px; }

#recruit .recruit-copy {
  margin-top: 50px; }
  #recruit .recruit-copy h2 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.01em; }
    @media screen and (min-width: 768px) {
      #recruit .recruit-copy h2 {
        font-size: 24px;
        text-align: center; } }
  #recruit .recruit-copy p {
    margin-top: 20px;
    line-height: 2;
    letter-spacing: 0.01em; }
    @media screen and (min-width: 768px) {
      #recruit .recruit-copy p {
        margin-top: 30px;
        line-height: 2.2;
        font-size: 14px; } }

#recruit .recruit-type {
  margin-top: 50px;
  border-top: 1px solid #F7F7F7;
  padding-top: 50px; }
  @media screen and (min-width: 768px) {
    #recruit .recruit-type {
      margin-top: 80px;
      padding-top: 80px; } }
  #recruit .recruit-type h3 {
    text-align: center;
    font-size: 18px;
    font-weight: bold; }
    @media screen and (min-width: 768px) {
      #recruit .recruit-type h3 {
        font-size: 20px; } }
  #recruit .recruit-type .recruit-type-list {
    margin-top: 30px;
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 768px) {
      #recruit .recruit-type .recruit-type-list {
        flex-direction: row;
        margin-top: 80px;
        justify-content: space-between; } }
    @media screen and (min-width: 768px) {
      #recruit .recruit-type .recruit-type-list dl {
        width: 48%; } }
    #recruit .recruit-type .recruit-type-list dl + dl {
      margin-top: 50px; }
      @media screen and (min-width: 768px) {
        #recruit .recruit-type .recruit-type-list dl + dl {
          margin-top: 0; } }
    #recruit .recruit-type .recruit-type-list dl dt {
      font-size: 18px;
      font-weight: bold;
      border-left: 4px solid #000;
      padding-left: 10px;
      line-height: 1; }
      @media screen and (min-width: 768px) {
        #recruit .recruit-type .recruit-type-list dl dt {
          font-size: 22px;
          padding-left: 20px; } }
    #recruit .recruit-type .recruit-type-list dl dd {
      margin-top: 15px; }
      @media screen and (min-width: 768px) {
        #recruit .recruit-type .recruit-type-list dl dd {
          margin-top: 30px; } }
      #recruit .recruit-type .recruit-type-list dl dd p + p {
        margin-top: 20px; }
      @media screen and (min-width: 768px) {
        #recruit .recruit-type .recruit-type-list dl dd p {
          font-size: 14px;
          line-height: 2; } }

#recruit .recruit-info {
  margin-top: 50px;
  border-top: 1px solid #F7F7F7;
  padding-top: 50px; }
  @media screen and (min-width: 768px) {
    #recruit .recruit-info {
      margin-top: 80px;
      padding-top: 80px; } }
  #recruit .recruit-info h3 {
    text-align: center;
    font-size: 18px;
    font-weight: bold; }
    @media screen and (min-width: 768px) {
      #recruit .recruit-info h3 {
        font-size: 20px; } }
  #recruit .recruit-info .recruit-info-list {
    margin-top: 30px; }
    @media screen and (min-width: 768px) {
      #recruit .recruit-info .recruit-info-list {
        margin-top: 80px; } }
    #recruit .recruit-info .recruit-info-list dl {
      display: flex; }
      #recruit .recruit-info .recruit-info-list dl + dl {
        margin-top: 20px;
        border-top: 1px solid #F7F7F7;
        padding-top: 20px; }
        @media screen and (min-width: 768px) {
          #recruit .recruit-info .recruit-info-list dl + dl {
            margin-top: 30px;
            padding-top: 30px; } }
      #recruit .recruit-info .recruit-info-list dl:last-child {
        padding-bottom: 20px; }
        @media screen and (min-width: 768px) {
          #recruit .recruit-info .recruit-info-list dl:last-child {
            padding-bottom: 30px; } }
      #recruit .recruit-info .recruit-info-list dl dt {
        width: 120px; }
        @media screen and (min-width: 768px) {
          #recruit .recruit-info .recruit-info-list dl dt {
            width: 200px; } }
      #recruit .recruit-info .recruit-info-list dl dd {
        width: calc(100% - 140px); }
        @media screen and (min-width: 768px) {
          #recruit .recruit-info .recruit-info-list dl dd {
            width: calc(100% - 220px); } }

#recruit .recruit-entry {
  margin-top: 80px; }
  #recruit .recruit-entry a {
    display: flex;
    max-width: 300px;
    font-family: "Roboto", sans-serif;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    border: 1px solid #000;
    margin: 0 auto;
    padding: 20px;
    text-decoration: none;
    color: #000;
    letter-spacing: 0.01em; }
    @media screen and (min-width: 768px) {
      #recruit .recruit-entry a {
        transform: scale(1);
        transition: all .1s ease; }
        #recruit .recruit-entry a:hover {
          transform: scale(0.95);
          opacity: 0.7; } }

#contact .contact-form {
  margin-top: 50px;
  text-align: center; }
  #contact .contact-form .contact-form-logo {
    text-align: center; }
    @media screen and (min-width: 768px) {
      #contact .contact-form .contact-form-logo img {
        max-width: 250px;
        width: 100%; } }
    #contact .contact-form .contact-form-logo p {
      margin-top: 10px; }
  #contact .contact-form .contact-form-link {
    margin-top: 40px;
    line-height: 2; }
    #contact .contact-form .contact-form-link a {
      color: #000; }
      #contact .contact-form .contact-form-link a:hover {
        text-decoration: none; }
