#contact {

  .contact-form {
    margin-top: 50px;
    text-align: center;

    .contact-form-logo {
      text-align: center;
      @include media-up(md){
        img {
          max-width: 250px;
          width: 100%;
        }
      }

      p {
        margin-top: 10px;
      }
    }
    .contact-form-link {
      margin-top: 40px;
      line-height: 2;

      a {
        color: $color-black;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
