#index {
  .index-works {
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -15px;

      @include media-up(md){
        margin-top: -15px;
      }

      li {
        width: calc(50% - 15px);
        margin-left: 15px;

        @include media-up(md){
          width: calc(33.333% - 15px);
          margin-left: 15px;
          margin-top: 15px;
        }

        @include media-up(lg){
          width: calc(25% - 15px);
        }

        @include media-up(xxl){
          width: calc(20% - 15px);
        }

        &:nth-child(n + 1) {
          margin-top: 20px;


          @include media-up(md){
            margin-top: 15px;
          }
        }

        &.is--pickup {
          width: 100%;

          @include media-up(lg){
            width: calc(50% - 15px);
          }
        }

        a {
          display: block;
          text-decoration: none;
          color: $color-black;

          @include media-up(md){
            transform: scale(1.0);
            transition: all .1s ease;

            &:hover {
              transform: scale(0.95);
              opacity: 0.7;
            }
          }
        }

        dl {
          position: relative;
          dt {
            font-size: 10px;
            position: absolute;
            bottom: 0px;
            left:0px;
            padding: 10px;
            width: 100%;
            color: $color-black;
            background-color: rgba(#fff,0.7);

            @include media-up(lg){
              font-size: 14px;
            }
          }

          dd {
            img {
              width: 100%;
              height: auto;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
  .index-about {
    @include media-up(md){
      padding-top: 50px;
      margin-top: -50px;
    }
  }
  .index-contact {
    @include media-up(md){
      padding-top: 50px;
      margin-top: -50px;
    }
  }
}
