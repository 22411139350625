#solution {
  .c-title {
    margin-top: 50px;
  }

  img {
    max-width: 100%;
  }

  .solution-cover {
    margin: 0px 0px 80px;
    img {
      max-width: 100%;
    }
  }

  .solution-list {
    margin-top: 50px;

    .solution-list-inner {
      @include media-up(md){
      }
    }

    dl {

      + dl {
        margin-top: 80px;

      }

      dt {
        font-size: 22px;
        font-weight: bold;

        @include media-up(md){
          font-size: 24px;
        }

        @include media-up(lg){
          font-size: 30px;
        }
      }

      dd {
        margin-top: 10px;

        @include media-up(lg){
          display: flex;
        }

        hr {
          border: 1px solid $color-gray;
          margin-top: 20px;
          margin-bottom: 20px;

          @include media-up(lg){
            display: none;
          }
        }

        .solution-info {
          font-size: 14px;

          @include media-up(lg){
            font-size: 15px;
            line-height: 2;
            width: 50%;
            margin-right: 5%;
          }
        }

        .solution-machine {
          font-size: 12px;

          @include media-up(lg){
            width: 45%;
            font-size: 13px;
          }
        }
      }
    }
  }

  .solution-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;

    + .solution-item {
      margin-top: 80px;
    }

    .solution-item__title {
      font-size: 22px;
      font-weight: bold;

      @include media-up(md){
        font-size: 24px;
      }

      @include media-up(lg){
        font-size: 30px;
      }
    }

    .solution-item__contents {
      margin-top: 10px;

      @include media-up(lg){
        display: flex;
        margin-top: 20px;
      }

      .solution-item__photo {
        @include media-up(lg){
          width: 40%;
        }
      }

      .solution-item__text {
        margin-top: 20px;

        @include media-up(md){
          display: flex;
          margin-top: 0;
        }

        @include media-up(lg){
          width: 58%;
          margin-left: 2%;
        }
        hr {
          border: 1px solid $color-gray;
          margin-top: 20px;
          margin-bottom: 20px;

          @include media-up(lg){
            display: none;
          }
        }

        .solution-info {
          font-size: 14px;

          @include media-up(md){
            width: 50%;
            margin-right: 5%;
          }

          @include media-up(lg){
            font-size: 15px;
            line-height: 2;
            width: 63%;
            margin-right: 2%;
          }

          span {
            font-size: 12px;
          }
        }

        .solution-machine {
          font-size: 12px;

          @include media-up(md){
            width: 45%;
          }

          @include media-up(lg){
            font-size: 13px;
            width: 35%;
          }
        }
      }
    }
  }

}
