#qa {
  .c-title {
    margin-top: 50px;
  }

  .qa-list {
    margin-top: 50px;

    dl {
      display: flex;
      flex-direction: column;

      + dl {
        margin-top: 60px;
      }

      dt {
        font-size: 14px;
        font-weight: bold;

        @include media-up(md){
          font-size: 16px;
        }

        @include media-up(lg){
          font-size: 18px;
        }
      }

      dd {
        margin-top: 20px;
        padding-left: 20px;
        border-left: 2px solid #000;

        strong {
          font-weight: bold;

          @include media-up(md){
            font-size: 16px;
          }

          @include media-up(lg){
            font-size: 18px;
          }
        }

        p {
          margin-top: 10px;
        }
      }
    }
  }
}
